import React, { useState } from 'react';
import clsx from 'clsx';
import { FaMinus } from 'react-icons/fa'; // Import the Minus icon
import tempLogo from '../../../assets/images/temp_logo_d.jpg';
import useLogoLounge from '../../../hooks/useLogoLounge';
import { ILogo } from '../../../types/logos.types';
import { likeLogo } from '../../../services/designer';
import avatarPlaceholder from '../../../assets/images/layout/logo_lounge_skull.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useModal from '../../../hooks/useModal';
import useToast from '../../../hooks/useToast';

interface LogoProps {
  section: string;
  index: number;
  award?: string;
  logo: ILogo;
  actionCallback?: any;
  logoRef?: React.RefObject<HTMLDivElement>;
  bookAward?: string;
  masterBookAward?: string;
}

const Logo: React.FC<LogoProps> = ({
  section,
  index,
  logo,
  actionCallback,
  logoRef,
  bookAward,
  masterBookAward,
}) => {
  const toast = useToast();
  const { designerId: myId } = useAuth();
  const { setType, openModal } = useModal();
  const isMyLogo = logo.designer_id === myId;
  const { setLogoIndex, setLogoId } = useLogoLounge();
  const navigate = useNavigate();
  const showCertInfo = false;
  const attribution = ['following', 'likes', 'collection'].includes(section);
  const sidebar = section === 'related';
  const [isHovered, setIsHovered] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // Determine if we are in a collection page
  const isCollectionPage = window.location.pathname.includes('/collectionX/');

  const handleLogoId = (type: 'collection' | 'detail') => {
    const newSearchParams = searchParams;
    newSearchParams.set('logo_id', logo.logo_id.toString());
    setLogoId(logo.logo_id);
    setSearchParams(newSearchParams);
    setType(type);
    openModal(type);
  };

  const handleLike = () => {
    const newState = logo.is_liked === 1 ? 0 : 1;
    likeLogo({ logo_id: logo.logo_id, state: newState }).then(response => {
      if (response) {
        if (actionCallback) {
          actionCallback(logo.logo_id, 'like', { value: newState });
        }
      } else {
        toast.addToast({
          type: 'error',
          message: newState ? 'Error liking logo.' : 'Error unliking logo.',
        });
      }
    });
  };

  const handleSaveClick = () => {
    setLogoIndex(index);
    setLogoId(logo.logo_id);
    handleLogoId('collection');
  };

  const handleRemoveClick = () => {
    // Logic to remove the logo from the collection
    console.log(`Removing logo ${logo.logo_id} from the collection`);
    if (actionCallback) {
      actionCallback(logo.logo_id, 'remove', { value: 0 });
    }
  };

  const logoAction = (type: 'collection' | 'detail') => {
    if (sidebar) return navigate('/logo/' + logo.logo_id);
    return handleLogoId(type);
  };

  return (
    <>
      {!sidebar && (
        <>
          <input
            type="checkbox"
            className={clsx('like_button_toggle')}
            id={`logo_${section}_like-${index + 1}`}
            checked={logo.is_liked === 1}
            onClick={handleLike}
            readOnly={true}
          />
          <input
            type="checkbox"
            className="save_button_toggle"
            checked={logo.is_collected === 1 && isHovered}
            id={`logo_${section}_save-${index + 1}`}
            readOnly={true}
          />
        </>
      )}
      <article
        className="logo_outer"
        ref={logoRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="action_outer_container">
          <div
            className={clsx(
              'logo',
              (bookAward || masterBookAward) && `has_award`,
              bookAward && bookAward
            )}
            onClick={() => logoAction('detail')}
            {...(!sidebar && { 'data-modal': `logo-${index + 1}` })}
          >
            <img
              className="image"
              src={logo.url || tempLogo}
              alt=""
              onLoad={e => {
                (e.target as HTMLImageElement).parentElement?.classList.add('loaded');
              }}
              onError={e => {
                e.currentTarget.src = tempLogo;
              }}
            />
            <div
              className={clsx(
                `master_award`,
                bookAward && 'second_award',
                masterBookAward ? masterBookAward : 'hidden'
              )}
            />
          </div>

          {!sidebar && (
            <>
              <div
                className={clsx(
                  'action_outer like_button_outer',
                  logo.is_liked === 1 && 'like_container'
                )}
              >
                <label
                  className="like_button"
                  htmlFor={`logo_${section}_like-${index + 1}`}
                  title="Like"
                >
                  Like
                </label>
              </div>
              <div
                className={clsx(
                  'action_outer save_button_outer',
                  logo.is_collected === 1 && 'like_container'
                )}
                onClick={handleSaveClick}
              >
                <label
                  className="save_button"
                  htmlFor={`logo_${section}_save-${index + 1}`}
                  title="Save"
                ></label>
              </div>

              {isMyLogo && (
                <div className="action_outer edit_button_outer">
                  <Link
                    className="edit_button"
                    to={`/edit?logoId=${logo.logo_id}`}
                    title="Edit"
                  >
                    Edit
                  </Link>
                </div>
              )}

              {/* Only show the Minus button if we are on the collection page */}
              {isCollectionPage && (
                <div
                  className="action_outer remove_button_outer"
                  onClick={handleRemoveClick}
                >
                  <label
                    className="remove_button"
                    title="Remove from Collection"
                  >
                    <FaMinus />
                  </label>
                </div>
              )}
            </>
          )}
        </div>

        {attribution && (
          <Link className="designer" to={`/designers/${logo.slug}?tab=logos`}>
            <img
              className="image"
              src={logo?.avatar_url || avatarPlaceholder}
              alt=""
              onError={e => (e.currentTarget.src = avatarPlaceholder)}
            />
            <span className="name">{logo?.company}</span>
          </Link>
        )}

        {showCertInfo && (
          <div className="logo_cert_info">
            <div>
              <strong>LogoLounge Book&nbsp;12</strong>
              <br />
              Birds
              <br />
              Page 100, 1C
            </div>
            <div>
              <strong>Downloads</strong>
              <br />
              <a href="/#" target="_blank" rel="noopener noreferrer">
                To share
              </a>
              <br />
              <a href="/#" target="_blank" rel="noopener noreferrer">
                To print
              </a>
            </div>
          </div>
        )}
      </article>
    </>
  );
};

export default Logo;
